<template>
  <div class="toSpei" @click="changeIdTypeList(0)">
    <div class="header"  v-if="false">
      <p>{{ $t('spei_amount') }}</p>
      <p>{{ $t('spei_mxn') }} {{ money }}</p>
      <!-- <p>5000</p> -->
    </div>
    <div class="contentBox"  v-if="false">
      <div class="idTypeBox">
        <p>{{ $t('spei_id_type') }}</p>
        <div @click.stop="changeIdTypeList(1)">{{ type }}</div>
        <ul v-if="idTypeFlag" @click="changeIdTypeList(0,$event)">
          <li>{{ $t('spei_rfc') }}</li>
          <li>{{ $t('spei_curp') }}</li>
        </ul>
      </div>
      <div class="idNumberBox">
        <p>{{ $t('spei_id_number') }}</p>
        <div>
          <input type="text" v-model="number" @input="inputIdNumber"/>
        </div>
        <p v-if="numberTips">{{ $t('spei_id_number_tips') }}</p>
      </div>
      <div class="idNumberBox">
        <p>{{ $t('spei_name') }}</p>
        <div>
          <input type="text" v-model="name" @input="inputName">
        </div>
        <p v-if="nameTips">{{ $t('spei_name_tips') }}</p>
      </div>
      <div class="idNumberBox">
        <p>{{ $t('spei_email') }}</p>
        <div>
          <input type="text" v-model="email" @input="inputEmail">
        </div>
        <p v-if="emailTips">{{ $t('spei_email_tips') }}</p>
      </div>
        <div class="btn" :class="btnType == 1 ? 'actBtn' : ''" @click="sendMsg">{{ $t('spei_confirm') }}</div>
    </div>
    <div class="loadiingBox" v-if="!sendFlag">
      <van-loading size="40" class="loading" type="spinner"/>
    </div>
  </div>
 
</template>
 
<script>
import { Toast } from "vant";
export default {
  name: "toSpei",
  data() {
    return {
      idTypeFlag:false,
      numberTips:false,
      nameTips:false,
      emailTips: false,
      type:'',
      number:'',
      name:'',
      email:'',
      money:'',
      clOutsideId:'',
      mallId:'',
      orderType:'',
      payType:'',
      sourceId:'',
      numberRule:{
        'RFC':/^[A-ZÑ&]{3,4}\d{6}[A-Z0-9]{3}$/,
        'CURP':/^[A-Z]{4}\d{6}[HM]{1}[A-Z]{2}[A-Z]{3}[A-Z0-9]{2}$/
      },
      btnType:0,
      sendFlag:true
    }
  },
  created() { 
    document.title = this.$t('spei_title');
    // this.getLocalStrage();
    // this.chageBtnType()
    this.getUrlData();
    //调用原生方法
    this.$completes.complete("getUserInfo");
    //原生调用h5方法
    window.getUserInfo = this.getUserInfo;
    window["getUserInfo"] = (data) => {
      this.getUserInfo(data);
    };
    // this.getUserInfo() 
  },
  methods: {
    getUrlData(){
      this.clOutsideId = this.$route.query.clOutsideId || '';
      this.mallId = this.$route.query.mallId || '';
      this.orderType = this.$route.query.orderType || '';
      this.payType = this.$route.query.payType || '';
      this.sourceId = this.$route.query.sourceId || '';
      this.money = this.$route.query.money || '0';
    },
    setLocalStrage(){
      localStorage.setItem("type", this.type);
      localStorage.setItem("number", this.number);
      localStorage.setItem("name", this.name);
      localStorage.setItem("email", this.email);
    },
    getLocalStrage(){
      this.type = localStorage.getItem("type") || 'RFC';
      this.number = localStorage.getItem("number") || '';
      this.name = localStorage.getItem("name") || '';
      this.email = localStorage.getItem("email") || '';
    },
    changeIdTypeList(type,event){
      this.idTypeFlag = type;
      if(type == 0 && event){
        this.type = event.target.innerText;
      }
    },
    inputIdNumber(){
      if(this.numberRule[this.type].test(this.number)){
        this.numberTips = false;
        this.chageBtnType()
      }else{
        this.numberTips = true
      }
    },
    inputName(){
      // 正则表达式解释: 
      // ^ 表示字符串的开始
      // [A-Za-z\s] 表示允许大小写字母和空格
      // {1,30} 表示长度至少为1个字符，最多30个字符
      // $ 表示字符串的结束
      // const regex = /^[A-Za-z\s]{1,30}$/;
      const regex = /^[\u4e00-\u9fa5A-Za-z\s]{1,30}$/;
      if(regex.test(this.name)){
        this.nameTips = false;
        this.chageBtnType()
      }else{
        this.nameTips = true;
      }
    },
    inputEmail(){
      // 正则表达式解释:
      // ^ 表示字符串的开始
      // [a-zA-Z0-9._%+-]+ 表示邮箱名部分，允许大小写字母、数字以及特定符号（. _ % + -）
      // @ 是邮箱中必须有的"@"符号
      // [a-zA-Z0-9.-]+ 表示域名部分，允许大小写字母、数字以及"."和"-"
      // \. 表示点号"."
      // [a-zA-Z]{2,} 表示顶级域部分，至少包含两个或以上的字母（例如.com,.org,.net等）
      // $ 表示字符串的结束
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if(regex.test(this.email)){
        this.emailTips = false;
        this.chageBtnType()
      }else{
        this.emailTips = true;
      }
    },
    chageBtnType(){
      if(!this.numberTips && !this.nameTips && !this.emailTips && this.name && this.number && this.email){
        this.btnType = 1;
      }else{
        this.btnType = 0;
      }
    },
    sendMsg(){
     this.createdOrder();
    },
    getUserInfo(data) {
      this.userInfo = JSON.parse(data);
      this.token = JSON.parse(data).token
      this.uid = JSON.parse(data).userId
      let langs = this.userInfo.lang.substring(0,2)
      this.langs = this.userInfo.lang.substring(0,2)
      let lang = this.userInfo.lang
      this.headers = {
          fid:  this.userInfo.fid,
          os:  this.userInfo.os || 1,
          lang: this.userInfo.lang,
          version:this.userInfo.version,
          deviceid:this.userInfo.deviceId,
        }

  //  this.token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiJOemt5TWpneiIsInh5eiI6IlJUQTNORFZCTkRBdE1qUkdRaTAwT1RKQ0xVRXhNak10T1VFMFJEUkdNek14UVVJdyIsImV4cCI6MTk4NDAyNTYzMH0.KrZfZ2yE5MHJ28PRPqUrVETKmHnJSZeeV-PYJYcxIoU';
  //     this.uid = '792283'; 
  //     this.headers = {
  //         fid: '2001',
  //         os: 1,
  //         lang: 'zh-CN',
  //         version: '3.0',
  //         deviceid: 'E0745A40-24FB-492B-A123-9A4D4F331AB0',
  //     }
  //     let langs = 'en'
  //     this.langs = langs
  //     let lang = langs
      if (langs == "ar") {
          this.$i18n.locale = "ar";
      } else if (langs == "tr") {
          this.$i18n.locale = "tr";
      } else if (langs == "es") {
          this.$i18n.locale = "es";
      } else if (langs == "pt") {
          this.$i18n.locale = "pt";
      } else {
          this.$i18n.locale = "en";
      }
      this.sendMsg()
    },
    // 
    async createdOrder(){
      // 获取活动时间
      let personLeveldatas = {
        option: "post",
        host:
          this.$serviceIpJava +
          "api/trade/order/create?token=" +
          this.token +
          "&uid=" +
          this.uid,
        data: {
          clOutsideId:this.clOutsideId,
          mallId:this.mallId,
          orderType:this.orderType,
          payType:this.payType,
          sourceId:this.sourceId,
          identifyType:this.type,
          identifyNumber:this.number,
          name:this.name,
          email:this.email,
        },
        lang: this.langs,
        header: this.headers,
      };
      if(this.sendFlag){
          this.sendFlag = false;
          let data =  await this.$axios.api(personLeveldatas.host,personLeveldatas.data,personLeveldatas.option,personLeveldatas.header)
          if (data.code == 200) {
            if(data.data.url){
              window.open(data.data.url)
              // this.setLocalStrage()
            }else{
              Toast(data.message || this.$t('failed'))
            }
            
          }else{
            Toast(data.message);
          }
        this.sendFlag = true;
      }
      
    },
  }
};
</script>

<style scoped>
@import './index.css';

</style>
